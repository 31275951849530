import React from 'react';

const TableSection = () => {
    return (
        <section key={'table-section'} className={`table-section`}>
            <div className="container">
                <div className="row align-items-center">
                    <h2 className={`text-center`}>Comparing Fast USDC <br/> to alternatives</h2>
                    <table>
                        <thead>
                        <tr>
                            <td>Feature</td>
                            <td>Traditional Bridging</td>
                            <td>Fast USDC</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Transfer Time</td>
                            <td>10-30 minutes</td>
                            <td>Under 2 minutes</td>
                        </tr>
                        <tr>
                            <td>Automation Readiness</td>
                            <td>Manual</td>
                            <td>Automated</td>
                        </tr>
                        <tr>
                            <td>Capital Efficiency</td>
                            <td>Low</td>
                            <td>High</td>
                        </tr>
                        <tr>
                            <td>User Experience</td>
                            <td>Fragmented</td>
                            <td>Seamless</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className='row justify-content-center'>
                        <div className='col-auto'>
                            <a href={`https://calendly.com/anjonr/fast-usdc-intro`} target='_blank' className='btn'>
                                Integrate Today
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TableSection;
