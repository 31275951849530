import React from 'react';
import {Helmet} from "react-helmet";
import ogimage from "../images/ogimages/fastusdc.png";
import {componentMap, DataProvider} from "../contentful/data-provider";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import TableSection from "../components/sections/_table-section";

const FastUsdc = ({data}) => {
    const dataProvider = new DataProvider(data);

    return (
        <Layout
            isDifferentLogo={false}>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Fast USDC',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Fast USDC',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Fast USDC</title>
            </Helmet>
            <div className={'team-fast-usdc-page'}>
                { dataProvider.getCurrentPageSections().map((section, index) => {
                    if (index === 3) {
                        return (
                            <>
                                <TableSection />
                                {dataProvider.getElementById(section.id) &&
                                    React.createElement(
                                        componentMap[section.type],
                                        {
                                            content: dataProvider.getElementById(section.id),
                                            key: index,
                                            withoutCta: true,
                                            forFastUsdc: true,
                                        }
                                    )}
                            </>
                        )
                    }

                    return (
                        <>
                            {dataProvider.getElementById(section.id) &&
                                React.createElement(
                                    componentMap[section.type],
                                    {
                                        content: dataProvider.getElementById(section.id),
                                        key: index,
                                        withoutCta: true,
                                    }
                                )}
                        </>
                    );
                })}
            </div>
        </Layout>
    );
};

export default FastUsdc;

export const query = graphql`
    query {
        contentfulPage(contentful_id: {eq: "2TqIKuE96Xcnxrm5NRA85l"}) {
            ... CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ... AllEntryDetails
                }
            }
        }
    }
`;
